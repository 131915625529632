
a {
  text-decoration: none
}

.new-label {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: green;
  display: block;
  width: 10px;
  min-height: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  z-index: 10;
  box-shadow: 0 0 0 rgba(0, 128, 0, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse2 {
  0% {
    -moz-box-shadow: 0 0 0 rgba(240, 0, 0, 1);
    box-shadow: 0 0 0 rgba(240, 0, 0, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.news-icon {
  width: fit-content;
  text-align: center;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
$a: 100vw;
$b: 90px;
.news-content{
  max-width:calc(#{$a} - #{$b});
}


